<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>切换门店：</p>
          <select v-model="form.fenpeimendianId" @change="qiehuanmendian">
            <option :value="0">全部门店</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li class="screen_right_li" title="全屏显示" @click="goScreenFull">
          <i class="icon-maximize"></i>
        </li>
        <!-- <li class="screen_right_li" title="锁定登录信息" @click="onLogin=true">
          <i class="icon-lock"></i>
        </li> -->
        <div class="clear"></div>
      </ul>
    </div>
    <div :class="screen_full?'screen_all screen_full':'screen_all'">
      <div class="screen">
        <div class="screen_header">东都MMS多功能会员管理系统数据汇总</div>
        <div class="screen_a">
          <div class="screen_a_1">
            <div class="screen_a_left"><i class="icon-slack"></i></div>
            <div class="screen_a_right">
              <h3>今日充值：￥<strong>{{countInt(thisDayData.CZ)}}</strong>.{{countFloat(thisDayData.CZ)}}元</h3>
            </div>
          </div>
          <div class="screen_a_2">
            <div class="screen_a_left"><i class="icon-airplay"></i></div>
            <div class="screen_a_right">
              <h3>今日营收：￥<strong>{{countInt(thisDayData.SF+thisDayData.LF)}}</strong>.{{countFloat(thisDayData.SF+thisDayData.LF)}}元</h3>
            </div>
          </div>
          <div class="screen_a_3">
            <div class="screen_a_left"><i class="icon-dollar-sign"></i></div>
            <div class="screen_a_right">
              <h3>今日收款：￥<strong>{{countInt(thisDayData.SF+thisDayData.LF-thisDayData.HK)}}</strong>.{{countFloat(thisDayData.SF+thisDayData.LF-thisDayData.HK)}}元</h3>
            </div>
          </div>
          <div class="screen_a_4">
            <div class="screen_a_left"><i class="icon-credit-card"></i></div>
            <div class="screen_a_right">
              <h3>今日划卡：￥<strong>{{countInt(thisDayData.HK)}}</strong>.{{countFloat(thisDayData.HK)}}元</h3>
            </div>
          </div>
          <div class="screen_a_5">
            <div class="screen_a_left"><i class="icon-trending-up"></i></div>
            <div class="screen_a_right">
              <h3>今日提成：￥<strong>{{thisDayData.TCZ}}</strong>.{{thisDayData.TCX}}元</h3>
            </div>
          </div>
        </div>
        <div class="screen_b">
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>月充值统计</h3>
            </div>
            <div class="screen_b_1_1" ref="screen_b_1_1">

            </div>
          </div>
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>月消费统计(包含划卡)</h3>
            </div>
            <div class="screen_b_1_1" ref="screen_b_1_2">

            </div>
          </div>
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>月提成统计</h3>
            </div>
            <div class="screen_b_1_1" ref="screen_b_1_3">

            </div>
          </div>
          <!-- <div class="screen_b_1" v-show="form.fenpeimendianId==0">
            <div class="screen_title">
              <h3>各门店营收占比（半年）</h3>
            </div>
            <div class="screen_b_1_1" ref="screen_b_1_4">

            </div>
          </div> -->
        </div>
        <div class="screen_c">
          <div class="screen_b_1">
            <div class="screen_title">
              <h3 @click="aaa">最新充值记录</h3>
            </div>
            <div class="screen_b_1_1">
              <div class="screen_c_1">
                <div class="screen_c_1_1">
                  <table class="screen_c_1_header">
                    <colgroup>
                      <col width="64">
                      <col >
                      <col >
                      <col >
                      <col width="80">
                      <col width="60">
                      <col width="40">
                      <col width="50">
                      <col width="40">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>编号</th>
                        <th class="textLeft">时间</th>
                        <th class="textLeft">账号</th>
                        <th class="textLeft">充值人员</th>
                        <th class="textLeft">门店</th>
                        <th>充金额</th>
                        <th>积分</th>
                        <th>付款</th>
                        <th>状态</th>
                      </tr>
                    </thead>
                  </table>
                  <div class="screen_c_1_1_1">
                    <table class="screen_c_1_body" :style="'margin-top:'+czList.czMarginTop+'px;transform:translateY('+czList.czTranslateY+'px);'+(czList.czTransition?'transition:transform .5s;':'')">
                      <colgroup>
                        <col width="40">
                        <col >
                        <col >
                        <col >
                        <col width="80">
                        <col width="60">
                        <col width="40">
                        <col width="50">
                        <col width="40">
                      </colgroup>
                      <tbody>
                        <tr v-for="item in czList.recordList" :key="item.id">
                          <td class="textCenter">{{item.id}}</td>
                          <td>{{getDateTime(item.chongzhishijian)}}</td>
                          <td>{{item.userPhone}}</td>
                          <td>{{item.chongzhirenyuanNames}}</td>
                          <td>{{item.fenpeimendianName}}</td>
                          <td class="textCenter">{{parseFloat((item.chongzhijine+item.zengsongjine).toFixed(2))}}</td>
                          <td class="textCenter">{{item.zengsongjifen}}</td>
                          <td class="textCenter">{{item.fukuanjine}}</td>
                          <td class="textCenter"><span :class="returnLBClass(item.state)">{{returnLB(item.state)}}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>最新消费记录</h3>
            </div>
            <div class="screen_b_1_1">
              <div class="screen_c_1">
                <div class="screen_c_1_1">
                  <table class="screen_c_1_header">
                    <colgroup>
                      <col width="64">
                      <col>
                      <col>
                      <col width="80">
                      <col>
                      <col width="60">
                      <col width="18%">
                      <col width="40">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>编号</th>
                        <th class="textLeft">时间</th>
                        <th class="textLeft">账号</th>
                        <th class="textLeft">门店</th>
                        <th class="textLeft">项目</th>
                        <th>总金额</th>
                        <th class="textLeft">实付款</th>
                        <th>状态</th>
                      </tr>
                    </thead>
                  </table>
                  <div class="screen_c_1_1_1">
                    <table class="screen_c_1_body" :style="'margin-top:'+xfList.czMarginTop+'px;transform:translateY('+xfList.czTranslateY+'px);'+(xfList.czTransition?'transition:transform .5s;':'')">
                      <colgroup>
                        <col width="40">
                        <col>
                        <col>
                        <col width="80">
                        <col>
                        <col width="60">
                        <col width="18%">
                        <col width="40">
                      </colgroup>
                      <tbody>
                        <tr v-for="item in xfList.recordList" :key="item.id">
                          <td class="textCenter">{{item.id}}</td>
                          <td>{{getDateTime(item.xiaofeishijian)}}</td>
                          <td>{{item.userPhone}}</td>
                          <td>{{item.fenpeimendianName}}</td>
                          <td>{{item.items}}</td>
                          <td class="textCenter">{{item.zongjijine}}</td>
                          <td>
                            {{item.shifukuan}}<span style="font-size:12px">({{getZFFS(item.zffs)}})</span>
                            <p v-if="item.shifujine">{{item.shifujine}}<span style="font-size:12px">({{getZFFS(item.zffs2)}})</span></p>
                          </td>
                          <td class="textCenter"><span :class="returnLBClass(item.state)">{{returnLB(item.state)}}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="screen_e">
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>本月消费项目排行</h3>
            </div>
            <div class="screen_b_1_1">
              <div class="screen_c_1">
                <div class="screen_c_1_1_1">
                  <table class="screen_c_1_body">
                    <colgroup>
                      <col width="40">
                      <col>
                      <col>
                      <col width="60">
                      <col width="80">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>排名</th>
                        <th class="textLeft">门店</th>
                        <th class="textLeft">项目名称</th>
                        <th>总数量</th>
                        <th>次卡数量</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in itemRank" :key="i">
                        <td class="textCenter">{{i+1}}</td>
                        <td>{{item.fenpeimendianName}}</td>
                        <td>{{item.itemName}}</td>
                        <td class="textCenter">{{item.Count}}</td>
                        <td class="textCenter">{{item.cishu}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>本月充值排行</h3>
            </div>
            <div class="screen_b_1_1">
              <div class="screen_c_1">
                <div class="screen_c_1_1_1">
                  <table class="screen_c_1_body">
                    <colgroup>
                      <col width="40">
                      <col>
                      <col width="80">
                      <col width="80">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>排名</th>
                        <th class="textLeft">门店</th>
                        <th class="textLeft">姓名</th>
                        <th>充值总额</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in CZRank" :key="i">
                        <td class="textCenter">{{i+1}}</td>
                        <td>{{item.fenpeimendianName}}</td>
                        <td>{{item.xingming}}</td>
                        <td class="textCenter">{{parseFloat(item.Count.toFixed(2))}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="screen_b_1">
            <div class="screen_title">
              <h3>本月提成排行</h3>
            </div>
            <div class="screen_b_1_1">
              <div class="screen_c_1">
                <div class="screen_c_1_1_1">
                  <table class="screen_c_1_body">
                    <colgroup>
                      <col width="40">
                      <col>
                      <col>
                      <col width="100">
                    </colgroup>
                    <thead>
                      <tr>
                        <th>排名</th>
                        <th class="textLeft">门店</th>
                        <th class="textLeft">姓名</th>
                        <th>总提成</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in TCRank" :key="i">
                        <td class="textCenter">{{i+1}}</td>
                        <td>{{item.fenpeimendianName}}</td>
                        <td>{{item.xingming}}</td>
                        <td class="textCenter">{{item.Count}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bigScren" v-if="onLogin">
      <div class="login_bt_table">
        <div class="login_bt_table_cell">
          <div class="login_bt_impor">
            <div class="login_bt_from">
              <div class="login_bt_from_line">
                <input type="text" v-model="guhua.userName" placeholder="请输入用户名" @keypress.enter="loginOn"/>
              </div>
              <div class="login_bt_from_line">
                <input type="password" v-model="guhua.passWord" placeholder="请输入密码" @keypress.enter="loginOn"/>
              </div>
              <div class="login_bt_from_botton">
                <button class="bigScrenButton quxiaoguhua" type="button" @click="quxiaoLogin">取消固化</button>
                <button class="bigScrenButton" type="button" @click="loginOn">固化登录</button>
                <button class="bigScrenButton quxiaoguhua" v-if="loginOnSession" type="button" @click="onLogin=false">关闭登录</button>
                
              </div>
              <div class="login_bt_from_line">
                <div class="login_err_meg">{{errMeg}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import axios from '@/http/axios'
import moment from 'moment'
import * as echarts from 'echarts'
import {defOption,Series,zffsList} from '@/data/data'
import {getUser,testStr} from '@/utils/util'
import { mapMutations } from "vuex"
export default {
  name: 'analysis',
  data() {
    return {
      zffsList:zffsList,
      itemRank:[],
      CZRank:[],
      TCRank:[],
      czList:{
        czTransition:true,
        czTranslateY:0,
        czMarginTop:0,
        recordList:[],
      },
      xfList:{
        czTransition:true,
        czTranslateY:0,
        czMarginTop:0,
        recordList:[],
      },
      thisDayData:{
        CZ:0,
        SF:0,
        LF:0,
        HK:0,
        TCZ:0,
        TCX:'00',
      },
      storeList:[],
      EmpList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
      },
      screen_full:false,
      echartBox:{
        mCZcount:null,
        mXFcount:null,
        mTCcount:null,
        mZBcount:null,
      },
      createInter:{
        five:null,
        two:null,
      },
      onLogin:false,
      errMeg:'',
      guhua:{
        userName:'',
        passWord:'',
      },
    }
  },
  created() {},
  computed:{
    loginOnSession(){
      if(sessionStorage.getItem('dongduUser')) return true
      else return false
    }
  },
  components: {},
  mounted() {
    this.watchFull()
    this.initEcharts()
  },
  methods: {
    ...mapMutations(['initNav','addVueXUser']),
    countInt(number){
      if(number) return parseInt(number)
      else return 0
    },
    countFloat(number){
      if(number){
        let x=String(number).split('.')[1]
        let float=x?x.length>1?Math.round(parseFloat('0.'+x)*100):x+'0':'00'
        return float
      }
      else return '00'
    },
    getEmpList(call){
      axios.post('/emp/list',this.form).then((res) => {
        if(res.code===5000){
          this.EmpList=res.data
          if(call) call()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    qiehuanmendian(){
      if(localStorage.getItem("bigScren")){
        let dongdu=JSON.parse(localStorage.getItem("bigScren"))
        dongdu.config.fenpeimendianId=this.form.fenpeimendianId
        localStorage.setItem('bigScren',JSON.stringify(dongdu))
      }
      this.getEmpList(()=>{
        this.addEchartsData()
      })
    },
    addEchartsData(){
      this.getThisDayData()
      this.refmCZcountData()
      this.refmXFcountData()
      this.refmTCcountData()
      this.getNewCZJL()
      this.getNewXFJL()
      this.getItemRank()
      this.getCZRank()
      this.getTCRank()
    },
    getTCRank(){
      axios.post('/dataCount/getTCRank',{...this.form}).then((res) => {
        if(res.code===5000){
          let arr=res.data.sort((a,b)=>b.Count-a.Count)
          arr.forEach(e=>{
            let store=this.storeList.find(a=>a.id==e.fenpeimendianId)
            let emp =this.EmpList.find(a=>a.id==e.userId)
            e.fenpeimendianName=store?store.storeName:'已删除'
            e.xingming=emp?emp.xingming:'已删除'
          })
          this.TCRank=arr
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getCZRank(){
      axios.post('/dataCount/getCZRank',{...this.form}).then((res) => {
        if(res.code===5000){
          let CZRank=this.EmpList.map(e=>{
            return{
              id:e.id,
              xingming:e.xingming,
              fenpeimendianName:e.fenpeimendianName,
              Count:0,
            }
          })
          res.data.forEach(e=>{
            let ids=[...new Set(e.chongzhirenyuanIds.split(','))]
            ids.forEach(a=>{
              let ren=CZRank.find(b=>b.id==a)
              if(ren) ren.Count+=e.Count
            })
          })
          this.CZRank=CZRank.sort((a,b)=>b.Count-a.Count)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getItemRank(){
      axios.post('/dataCount/getItemCount',{...this.form}).then((res) => {
        if(res.code===5000){
          let arr=res.data.sort((a,b)=>b.Count-a.Count)
          arr.forEach(e=>{e.fenpeimendianName=this.storeList.find(a=>a.id==e.fenpeimendianId).storeName})
          this.itemRank=arr
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getNewXFJL(){
      axios.post('/dataCount/xiaofeiJL',this.form).then((res) => {
        if(res.code===5000){
          if(this.xfList.recordList.length){
            this.xfList.czTransition=true
            let index = res.data.findIndex(e=>e.id==this.xfList.recordList[0].id)
            if(index>=0){
              let arr = res.data.slice(0,index)
              this.xfList.recordList.unshift(...arr)
              this.xfList.czMarginTop=index*-36
              this.xfList.czTranslateY=index*36
            }else{
              this.xfList.recordList.unshift(...res.data)
              this.xfList.czMarginTop=-360
              this.xfList.czTranslateY=360
            }
            window.setTimeout(()=>{
              this.xfList.czTransition=false
              this.xfList.czMarginTop=0
              this.xfList.czTranslateY=0
              this.xfList.recordList=res.data
            },600)
          }else this.xfList.recordList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getNewCZJL(){
      axios.post('/dataCount/chongzhiJL',this.form).then((res) => {
        if(res.code===5000){
          if(this.czList.recordList.length){
            this.czList.czTransition=true
            let index = res.data.findIndex(e=>e.id==this.czList.recordList[0].id)
            if(index>=0){
              let arr = res.data.slice(0,index)
              this.czList.recordList.unshift(...arr)
              this.czList.czMarginTop=index*-36
              this.czList.czTranslateY=index*36
            }else{
              this.czList.recordList.unshift(...res.data)
              this.czList.czMarginTop=-360
              this.czList.czTranslateY=360
            }
            window.setTimeout(()=>{
              this.czList.czTransition=false
              this.czList.czMarginTop=0
              this.czList.czTranslateY=0
              this.czList.recordList=res.data
            },600)
          }else this.czList.recordList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    addOption(data,type){
      let option={
        ...defOption,
        dataset:{
          source:[],
          sourceHeader:true,
        },
        series:[]
      }
      let oneDate=[]
      if(this.form.fenpeimendianId==0){
        oneDate=['数据统计',...this.storeList.map(e=>e.storeName)]
      }else{
        let store=this.storeList.find(e=>e.id==this.form.fenpeimendianId)
        if(store) oneDate=['数据统计',store.storeName]
      }
      option.dataset.source.push(oneDate)
      let thisdate=moment().subtract(6,'M')
      for(let i=0;i<6;i++){
        thisdate=thisdate.add(1,'M')
        let lineData=[thisdate.month()+1+'月']
        if(this.form.fenpeimendianId==0){
          this.storeList.forEach((e)=>{
            let arr=data.find(a=>a.fenpeimendianId==e.id&&a.month==thisdate.month()+1)
            if(arr){
              lineData.push(parseFloat(arr.Count.toFixed(2)))
            }else lineData.push(0)
          })
        }else{  
          let arr=data.find(a=>a.month==thisdate.month()+1)
          if(arr){
            lineData.push(parseFloat(arr.Count.toFixed(2)))
          }else lineData.push(0)
        }
        option.dataset.source.push(lineData)
      }
      if(this.form.fenpeimendianId==0){
        for(let i=0;i<this.storeList.length;i++){
          option.series.push({
            ...Series,
            type,
          })
        }
      }else{
        option.series=[
          {
            ...Series,
            type,
          }
        ]
      }
      return option
    },
    refmZBcountData(data){
      let option={
        title: { show: false },
        legend: {
          orient: 'vertical',
          textStyle: {
            textBorderColor: "#FFF",
            textBorderWidth:3,
          },
          top: 10,
          right:10,
        },
        grid: {
          left: 50,
          top: 50,
          bottom: 30,
          right: 20,
        },
        tooltip: {
          trigger: 'item'
        },
        dataset:{
          source:[
            ['各门店营收占比（半年）',0]
          ],
          sourceHeader:true,
        },
        series: [
          {
            name: '各门店营收占比（半年）',
            type: 'pie',
            radius: '70%',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label:{
              show:true,
              formatter:'{b} ：{d}%'
            },
          }
        ]
      }
      this.storeList.forEach((e)=>{
        let lineData=[e.storeName]
        let arr=data.filter(a=>a.fenpeimendianId==e.id)
        if(arr.length){
          lineData.push(eval(arr.map(b=>b.Count).join("+")))
        }else lineData.push(0)
        option.dataset.source.push(lineData)
      })
      this.echartBox.mZBcount.setOption(option,true)
      this.echartBox.mZBcount.resize()
    },
    refmTCcountData(){
      if(this.echartBox.mTCcount){
        axios.post('/dataCount/getTCCount',this.form).then((res) => {
          if(res.code===5000){
            let option=this.addOption(res.data,'line')
            this.echartBox.mTCcount.setOption(option,true)
            this.echartBox.mTCcount.resize()
          }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
        })
      }
    },
    refmXFcountData(){
      if(this.echartBox.mXFcount){
        axios.post('/dataCount/getXFCount',this.form).then((res) => {
          if(res.code===5000){
            let option=this.addOption(res.data,'bar')
            this.echartBox.mXFcount.setOption(option,true)
            this.echartBox.mXFcount.resize()
            // if(this.echartBox.mZBcount&&this.form.fenpeimendianId==0){
            //   this.refmZBcountData(res.data)
            // }
          }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
        })
      }
    },
    refmCZcountData(){
      if(this.echartBox.mCZcount){
        axios.post('/dataCount/getCZCount',this.form).then((res) => {
          if(res.code===5000){
            let option=this.addOption(res.data,'line')
            this.echartBox.mCZcount.setOption(option,true)
            this.echartBox.mCZcount.resize()
          }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
        })
      }
    },
    initEcharts(){
      this.echartBox.mCZcount=shallowRef(echarts.init(this.$refs.screen_b_1_1))
      this.echartBox.mXFcount=shallowRef(echarts.init(this.$refs.screen_b_1_2))
      this.echartBox.mTCcount=shallowRef(echarts.init(this.$refs.screen_b_1_3))
      this.getUser()
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    getThisDayData(){
      axios.post('/dataCount/thisDayData',this.form).then((res) => {
        if(res.code===5000){
          const{dayCZ,dayHK,dayRZ,dayTC}=res.data
          let CZ=0,HK=0,SF=0,LF=0,TCZ=0,TCX='00'
          if(dayCZ[0]&&dayCZ[0].dayCZ){
            CZ=dayCZ[0].dayCZ
          }
          if(dayHK[0]&&dayHK[0].dayHK){
            HK=dayHK[0].dayHK
          }
          if(dayRZ[0]&&dayRZ[0].daySF){
            SF=dayRZ[0].daySF
          }
          if(dayRZ[0]&&dayRZ[0].dayLF){
            LF=dayRZ[0].dayLF
          }
          if(dayTC[0]&&dayTC[0].dayTC){
            TCZ=Math.trunc(dayTC[0].dayTC)
            let x=String(dayTC[0].dayTC).split('.')[1]
            TCX=x?x.length>1?Math.round(parseFloat('0.'+x)*100):x+'0':'00'
          }
          this.thisDayData={CZ,HK,SF,LF,TCZ,TCX}
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    watchFull(){
      document.addEventListener('fullscreenchange', ()=>{
        if(document.fullscreenElement==null) {
          this.screen_full=false
          this.addEchartsData()
        }
      });
    },
    goScreenFull(){
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      this.screen_full=true
      this.addEchartsData()
    },
    quxiaoLogin(){
      this.$trueOrFalse({
        title: '重要提示',
        content: '取消固化将清除此设备所有登录信息！',
        torf: true,
        suc:()=>{
          localStorage.removeItem('bigScren')
          sessionStorage.removeItem("dongduUser")
          this.$Mesg({content:"取消成功！"})
          this.$router.push('/')
        },
      })
    },
    loginOn(){
      this.$trueOrFalse({
        title: '重要提示',
        content: '固化登录表示您相信此设备，在您修改密码前，此设备打开本页面将不需登录！',
        torf: true,
        suc:()=>{
          this.errMeg= '登录中……'
          if(testStr(this.guhua.userName)){
            this.errMeg= '用户名不能为空！'
            return false
          } 
          if(testStr(this.guhua.passWord)){
            this.errMeg='密码不能为空！'
            return false
          } 
          axios.post('/login',this.guhua).then((res) => {
            if(res.code===5000){
              let userObj={
                userName:this.guhua.userName,
                token:res.data,
                user:res.user
              }
              localStorage.setItem('bigScren',JSON.stringify({User:this.guhua,config:{fenpeimendianId:this.form.fenpeimendianId}}))
              this.addVueXUser(res.user)
              let user =getUser(this.guhua.userName)
              if(user){
                this.initNav(user.navArr)
              }
              sessionStorage.setItem('dongduUser',JSON.stringify(userObj))
              if(res.user.daoqishijian<=30){
                this.$Mesg({
                  content: `您的账户还有${res.user.daoqishijian}天到期，请尽快处理。`,
                  errOrSuc: 'war',
                })
              }
              this.$parent.$parent.addDate(res.user.date,res.user.daoqishijian)
              this.getStore()
              this.form.fenpeimendianId=res.user.fenpeimendianId
              this.errMeg=''
              this.onLogin=false
            }else{
              this.errMeg=''
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getUser(){
      if (sessionStorage.getItem('dongduUser')) {
        this.getEmpList(()=>{
          this.getStore()
        })
      }else{
        if (localStorage.getItem('bigScren')) {
          let Dongdu = JSON.parse(localStorage.getItem('bigScren'))
          axios.post('/login',Dongdu.User).then((res) => {
            if(res.code===5000){
              let userObj={
                userName:Dongdu.User.userName,
                token:res.data,
                user:res.user
              }
              this.addVueXUser(res.user)
              let user =getUser(Dongdu.User.userName)
              if(user){
                this.initNav(user.navArr)
              }
              sessionStorage.setItem('dongduUser',JSON.stringify(userObj))
              if(res.user.daoqishijian<=30){
                this.$Mesg({
                  content: `您的账户还有${res.user.daoqishijian}天到期，请尽快处理。`,
                  errOrSuc: 'war',
                })
              }
              this.$parent.$parent.addDate(res.user.date,res.user.daoqishijian)
              this.form.fenpeimendianId=Dongdu.config.fenpeimendianId
              this.getEmpList(()=>{
                this.getStore()
              })
            }else{
              this.onLogin=true
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        }else{
          this.onLogin=true
        }
      }
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.addEchartsData()
          this.createInter.five=window.setInterval(()=>{
            this.refmCZcountData()
            this.refmXFcountData()
            this.refmTCcountData()
            this.getItemRank()
            this.getCZRank()
            this.getTCRank()
          },300000)
          this.createInter.two=window.setInterval(()=>{
            this.getThisDayData()
            this.getNewCZJL()
            this.getNewXFJL()
          },120000)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else {
        return 'err'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    getZFFS(num){
      let zffs=this.zffsList.find(e=>e.id==num)
      if(zffs) return zffs.name
      else{
        if(num==1) return "会员扣款"
        else return '无'
      }
    },
    
  },
  unmounted(){
    if(this.createInter.five){
      window.clearInterval(this.createInter.five)
    }
    if(this.createInter.two){
      window.clearInterval(this.createInter.two)
    }
  }
}
</script>
